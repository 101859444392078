import React, { useContext, useState, forwardRef } from 'react'
import { ThemeContext } from "../../context/ThemeContext"
import { PageComponent } from '../../components/PageComponent/PageComponent'
import { StyledP, StyledInput, StyledLi, StyledUl, StyledDiv, StyledBackButton, StyledForm, StyledA } from '../../components/StyledComponents/StyledComponents'
import { useMediaQuery } from 'react-responsive'

export const ContentPage = forwardRef((props, contentRef) =>  {

    const { colors } = useContext(ThemeContext)
    const [input, setInput] = useState("")
    const [content, setContent] = useState("")

    const leftBg = "lightgreen"
    const rightBg = "darkgreen"

    const projects = [
        {name: "proximate", url: "https://tomgrooves.github.io/react-proximate/", text: <StyledP colors={colors}>{`Proximate is an app written in React. It shows you what is happening around you by grabbing nearby points of interest from wikidata \n \n`}<StyledA href="https://tomgrooves.github.io/react-proximate/">Check it out here</StyledA></StyledP>},
        {name: "stringsonline", url: "https://tomgrooves.github.io/stringsonline/", text:  <StyledP colors={colors}>{`Stringsonline is a webshop written in React. The shop sells music equipment and is feature rich with shopping basket, checkout flow and ratings \n \n`} <StyledA href="https://tomgrooves.github.io/stringsonline/">Check it out here</StyledA> </StyledP>},
        {name: "mycomponents", url: "https://github.com/TomGrooves/myComponents", text: <StyledP colors={colors}>{`myComponents is a personal vault of React goodies. It is a collection of small reusable components for React \n \n`}<StyledA href="https://github.com/TomGrooves/myComponents">Check it out here</StyledA></StyledP>},
        {name: "berlin clock", url: "https://github.com/TomGrooves/berlin_clock", text: <StyledP colors={colors}>{`The Berlin Clock is a clock situated in berlin. It shows the time in a quite unusually fashion. The project was coded in vanilla JS and HTML5 \n \n`}<StyledA href="https://github.com/TomGrooves/berlin_clock">Check it out here</StyledA></StyledP>},
    ]

    const isMobile = useMediaQuery({ maxWidth: 762 })


    const checkInput = (e) => {
        
        e.preventDefault()
        let status = false;
        let text = ""
        for (const project in projects){
            if (input.toLowerCase() === projects[project].name){
                status = true;
                text = projects[project].text
            }
        }

        if (status === true){
            setContent(<StyledBackButton onClick={() => goBack()}>{text}</StyledBackButton>)
            setInput("")
        }
        else {
            setInput("project not found...")
            setTimeout(() => {
                setInput("")
            }, 3000)
        }
    }

    const goBack = () => {
        setContent("")
        setInput("")
    }

    const contentLeft = <StyledUl colors={colors}>
        {<StyledLi>{`let projects = [`}</StyledLi>}
            {projects.map((item, i) => {
                return <StyledLi colors={colors} key={i}><a href={`${item.url}`}>{item.name + ','}</a></StyledLi>
            })}
        {<StyledLi>{`]`}</StyledLi>}
        </StyledUl>

    const contentRight =
    <StyledDiv colors={colors}>
        
        {content === "" ? <StyledP colors={colors}> {`To read more about a project type in the project name and hit enter: 
        \n 
        \n`}</StyledP>
        : content}

        {content === "" ? 
            <StyledForm label="content_description_form" onSubmit={(e) => checkInput(e)}> 
                <StyledInput label="content_description_input" value={input} onChange={(e)=> setInput(e.target.value)} colors={colors}></StyledInput>
            </StyledForm> : null}
    </StyledDiv> 

    return (
        <PageComponent areaRef={contentRef} left={contentLeft} right={!isMobile ? contentRight : null} left_bg={leftBg} right_bg={rightBg} />
    )
})