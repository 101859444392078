import React, { useState } from 'react'
import {StyledLi, StyledNav, StyledMenuP, StyledMenuUl} from '../../components/StyledComponents/StyledComponents'


export function NavComponent(props){

    const [open, setOpen] = useState(false)

    const executeScroll = (page) => props.handleBackClick(page)

    return (

        <StyledNav>
            <StyledMenuP onClick={() => setOpen(open === true ? false : true)}>.menu</StyledMenuP>
            <StyledMenuUl open={open}>
                <StyledLi onClick={() => {setOpen(false); executeScroll(0)}}>.start</StyledLi>
                <StyledLi onClick={() => {setOpen(false); executeScroll(1)}}>.about</StyledLi>
                <StyledLi onClick={() => {setOpen(false); executeScroll(2)}}>.projects</StyledLi>
                <StyledLi onClick={() => {setOpen(false); executeScroll(3)}}>.contact</StyledLi>
            </StyledMenuUl>
        </StyledNav>
    )
}