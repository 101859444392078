import React, {useContext} from 'react'
import { ThemeContext } from "../../context/ThemeContext"
import {StyledDiv, StyledSection} from '../StyledComponents/StyledComponents'

export function PageComponent(props){

    const {colors} = useContext(ThemeContext)
  
    return (

        <>
            <StyledSection ref={props.areaRef} colors={colors}>
                <StyledDiv colors={colors} bg={props.left_bg}>{props.left}</StyledDiv>
                {props.right !== null && <StyledDiv colors={colors} bg={props.right_bg}>{props.right}</StyledDiv>} 
            </StyledSection>
        </> 
    )
}
