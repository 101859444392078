import styled, {keyframes} from 'styled-components'
import portrait from '../../assets/images/portrait.webp'

    const StyledSection = styled.section`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:0;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
    `;

    const StyledDiv = styled.div`
    
        padding: 0px;
        margin: 0px;
        height: 100vh; 
        background-color: ${props => 
            props.bg === "darkgreen" ? props.colors.darkgreen : 
            props.bg === "lightgreen" ? props.colors.lightgreen : 
            props.bg === "brown" ? props.colors.brown :
            props.bg === "red" ? props.colors.red : 
            null} 
        `;

    const StyledImage = styled.figure`
        background-image: url("${portrait}");
        min-height: 100vh;
        width: auto;
        background-position: center center;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-blend-mode:darken;
        background-color: ${props => props.colors.red};
        margin: 0;
        padding: 0;

        @media (max-width: 968px) {
            background-attachment: scroll;
          }
    `;

    const StyledP = styled.p`
        text-align:center;
        display:block;
        width: 75%;
        margin:auto;
        margin-top: 30%;
        font-size: 1.8rem;
        letter-spacing: 4px;
        white-space: pre-wrap;
        color: #e5e5e5;
        text-align:start;
        line-height: 36px;

        @media (max-width: 968px) {
            margin-top: 25%;
            font-size: 1.2rem;
          }
        `;

    const StyledInput = styled.input`
        border:none;
        border-bottom: solid 3px white;
        width: 25vw;
        outline:none;
        background-color: ${props => props.colors.darkgreen};
        color:${props => props.colors.white};
        font-size: 2rem;
    `;


    const StyledH1 = styled.h1`
        font-weight: 400;
        box-sizing: border-box;
        font-size: 3.2em;
        letter-spacing: 4px;
        white-space: pre-wrap;
        color: ${props => props.colors.lightgreen};
        margin:auto;
        width: fit-content;
        text-align:start;
        padding-top:15vh;
        pointer-events: none;
        @media (max-width: 968px) {
            font-size: 1.4em;
            padding-top:30vh;
          }
        `;

    const StyledH2 = styled.h2`     
        font-size: 5.1rem;
        letter-spacing: 4px;
        text-align: ${props => props.pos};
        padding-top: 10vh;
        white-space: pre-wrap;
        color: ${props => props.color === "red" ? props.colors.red : props.color === "brown" ? props.colors.brown : null};
        pointer-events:none;

        @media (max-width: 968px) {
            font-size: 2.9rem;
          }
        `;

    const StyledH3 = styled.h3`     
        font-size: 3.1rem;
        letter-spacing: 4px;
        text-align: ${props => props.pos};
        padding-top: 1vh;
        margin:0;
        white-space: pre-wrap;
        color: ${props => props.color === "red" ? props.colors.red : props.color === "brown" ? props.colors.brown : null};
        pointer-events:none;

        @media (max-width: 968px) {
            font-size: 1.5rem;
          }
        `;

    const StyledHeader = styled.header`
        width: 100%;
        background-color: ${props => props.colors.darkgreen};
        height: 100vh;
    `;


    const StyledIcon = styled.div`
        svg{
            display: block;
            margin: auto;
            font-size: 10vw;
            color: ${props => props.color === 'red' ? props.colors.red : props.color === 'brown' ? props.colors.brown : null};

            @media (max-width: 968px) {
                font-size: 20vw;
                margin-top:15%;
            }
        }

        a{
            text-decoration:none;
            margin:0;
        }
        
        `;

    const StyledMenuP = styled.p`    
        text-align:center;
        display:block;
        font-size: 1.8rem;
        letter-spacing: 4px;
        white-space: pre-wrap;
        color: #e5e5e5;
        text-align:start;
        margin:0;
        cursor: pointer;

        @media (max-width: 968px) {
            font-size: 1.4rem;
          }
    `
    const StyledNav = styled.nav`
        position: fixed;
        left: 2%;
        top: 2%;
        width: auto;
    `;

    const StyledMenuUl = styled.ul`
        font-size: 2rem;
        color: #e5e5e5;
        text-align:start;
        display:${props => props.open === false ? 'none' : 'block'};
        margin:2px;
        padding:8px;
        animation:  ${props => props.open === false ? show : hide} 0.6s linear 1;
        pointer-events: ${props => props.open === false ? 'none' : 'all'};
        animation-fill-mode: both;
        cursor: pointer;

        @media (max-width: 968px) {
            font-size: 1.2rem;
          }

        li{
            font-size: 1.6rem;
            margin:0;
            padding:0;
            transition: color, 0.2s ease;

            @media (max-width: 968px) {
                font-size: 1rem;
              }
        }

        li:hover{
            color: #a1a1a1;
        }
    `;

        const StyledUl = styled.ul`
        color:#e5e5e5;
        text-align:center;
        display:block;
        font-size: 1.8rem;
        width: 50%;
        margin:auto;
        margin-top: 30%;
        color: #e5e5e5;
        text-align:start;
        
        @media (max-width: 968px) {
            margin:none;
            margin-top: 50%;
            font-size: 1.4rem;
            padding:unset;
            width: fit-content;
        }
    `;


    const StyledLi = styled.li`
        letter-spacing: 4px;
        text-align:center;
        display:block;
        font-size: 1.8rem;
        white-space: pre-wrap;
        text-align:start;
        margin:8px;
        padding:0;

        @media (max-width: 968px) {
            font-size: 1.2rem;
          }

        a{
            color: #e5e5e5;
            text-decoration: none;
            margin:4px;
            padding:4px;
            transition: color 0.2s ease;
        }
        a:hover{
            color: #a1a1a1;
        }
    `;

    const StyledA = styled.a`
        color: #e5e5e5;
        text-decoration: none;
        margin:0px;
        text-align:start;
        padding:0px;
        text-decoration:none;
        transition: color 0.2s ease;

        :hover{
            color: #a1a1a1;
        }

    `

    const StyledForm = styled.form`
        text-align:center;
        margin:auto;
    `;

    const StyledScroll = styled.div.attrs(props => ({
        style: {
            width: props.scroll + "%",
        },}))`
        height: 6px;
        position: fixed;
        margin:auto;
        top:0%;
        left:0%;
        background-color: ${props => props.colors.white};
        transition: 0.1s ease;
        overflow:hidden;
        -webkit-overflow-scrolling:touch;
    `; 

    const StyledBackButton = styled.div`
    svg{
        display: block;
        margin: auto;
        font-size: 5vw;
        color: #e5e5e5;
        margin: 8px;
    }
    cursor: pointer;
    `;

    const StyledTopButton = styled.div`
        position:fixed;
        pointer-events: ${props => props.scroll > 25 ? 'all' : 'none'};
        border:solid 6px #e5e5e5;
        right:2%;
        top: 85%;
        margin: auto;
        animation:  ${props => props.scroll > 25 ? hide : show} 0.6s linear 1;
        animation-fill-mode: both;
        svg{
            display: block;
            margin: auto;
            font-size: 4vw;
            color: #e5e5e5;
            margin: 8px;
        }
    `;

    const StyledBanner = styled.div`
        display:grid;
        grid-template-columns: 1fr 0.1fr;
        position:fixed;
        bottom:0;
        left:0;
        width: 100%;
        background-color: #e5e5e5;
        opacity: 1;
        align-items: center;
        justify-items: center;


        h2{
            font-size: 1.4rem;
            color:black;
            text-align:center;
            margin-left:10%;
        }
    `;

    const StyledAcceptButton = styled.button`
        width: 85%;
        height: 70%;
        background-color: #111;
        color: #e5e5e5;
        border: none;
        border-radius: 8px;
        font-size: 1.4rem;
        margin-right: 25%;
        transition: all 0.2s ease;

        :hover{
            border:solid 2px #111;
            color: #111;
            background-color: #e5e5e5;
        }

    `;

    const hide = keyframes`
    from {
        opacity:0%;
    }
  
    to {
        opacity:100%;
    }
  `;

    const show = keyframes`
    from {
        opacity:100%;
    }

    to {
        opacity:0%;
    }
`;

export {  
    StyledDiv,
    StyledH1,
    StyledH2,
    StyledH3,
    StyledHeader,
    StyledImage,
    StyledInput,
    StyledP,
    StyledSection,
    StyledIcon,
    StyledUl,
    StyledLi,
    StyledBackButton,
    StyledNav,
    StyledMenuUl,
    StyledMenuP,
    StyledForm,
    StyledScroll,
    StyledTopButton,
    StyledA,
    StyledAcceptButton,
    StyledBanner,
}