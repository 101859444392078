import React, {useRef} from 'react';
import { BackButtonComponent } from './components/BackButtonComponent/BackButtonComponent';
import { NavComponent } from './components/NavComponent/NavComponent';
import { ThemeContextProvider } from './context/ThemeContext';
import { AboutPage } from './pages/StartPage/AboutPage';
import { ContactPage } from './pages/StartPage/ContactPage';
import { ContentPage } from './pages/StartPage/ContentPage';
import { StartPage } from './pages/StartPage/StartPage';

function App() {

  const startPageRef = useRef(null)
  const aboutPageRef = useRef(null)
  const contentPageRef = useRef(null)
  const contactPageRef = useRef(null)

  function handleBackClick(page) {

    if (page === 0){
      startPageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (page === 1){
      aboutPageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (page === 2){
      contentPageRef.current.scrollIntoView({ behavior: 'smooth'})
    }
    if (page === 3){
      contactPageRef.current.scrollIntoView({ behavior: 'smooth'})
    }
}


// Issues
// Smooth scroll not working on phone
// Background image too large on small devices + no parallax
// ScrollComponents jumps when scrolling

  return (
    <ThemeContextProvider>
      <NavComponent handleBackClick={handleBackClick}/>
      <BackButtonComponent handleBackClick={handleBackClick}/>
      <StartPage ref={startPageRef}/>
      <AboutPage ref={aboutPageRef}/>
      <ContentPage ref={contentPageRef}/>
      <ContactPage ref={contactPageRef}/>
    </ThemeContextProvider>
  );
}

export default App;
