import React, { useContext } from 'react'
import {StyledScroll} from '../../components/StyledComponents/StyledComponents'
import { ThemeContext } from '../../context/ThemeContext'

export function ScrollCompomponent(){

    const {colors, scrollPercent} = useContext(ThemeContext);

    return (
        <StyledScroll colors={colors} scroll={scrollPercent}/>
    )
}