import React, {useContext, forwardRef} from 'react'
import Typewriter from 'typewriter-effect';
import { ThemeContext } from "../../context/ThemeContext"
import {StyledH1, StyledHeader} from '../../components/StyledComponents/StyledComponents'
import { ScrollCompomponent } from '../../components/ScrollComponent/ScrollComponent';

export const StartPage = forwardRef((props, startRef) => {

    const {colors} = useContext(ThemeContext)

    let text = <Typewriter
    onInit={(typewriter) => {
        typewriter.typeString(`function Welcome () { \n  let name = "kasper" \n  let age = 30 \n  console.log(name, age)\n \n  return (  \n    myPortfolio\n  )\n }`)
        .changeDelay(10)
          .start();
      }}
    />
    
    return(
        <StyledHeader ref={startRef} colors={colors}>
            <ScrollCompomponent/>
            <StyledH1 colors={colors}>
                {text}
            </StyledH1>
        </StyledHeader>)
})