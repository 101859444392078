import React, { createContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {


    const [scrollPercent, setScrollPercent] = useState("0")

    function saveScroll() {
      setScrollPercent(window.pageYOffset / (window.document.body.offsetHeight * 0.75) * 100)
    }
  
    useEffect(() => {
      function Scroll() {
        window.addEventListener("scroll", saveScroll);
      }

      Scroll();
      return () => {
        window.removeEventListener("scroll", saveScroll);
      };
    });

const colors = {
    darkgreen: "#566047",
    lightgreen: "#9DAF72",
    brown: "#736938",
    red: "#A66C4B",
    white: "#E5E5E5",
}

// Return AppContext.Provider with value={ALL THE VALUES}
    return (
        <ThemeContext.Provider value={{
            colors,
            scrollPercent,
            }}>
            {children}
        </ThemeContext.Provider>
    );
}

// Now import {AppContextProvider} from './context/ContextProvider'; in Top Hierachi (index.js)
// Then import { AppContext } from "../../context/ContextProvider" inside component that subscribes &  declare it with: const {testState, setTestState } = useContext(AppContext);

export { ThemeContext, ThemeContextProvider } 