import React, { useContext, forwardRef } from 'react'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { StyledH2, StyledIcon, StyledH3 } from '../../components/StyledComponents/StyledComponents'
import { PageComponent } from '../../components/PageComponent/PageComponent';
import { ThemeContext } from '../../context/ThemeContext';
import { useMediaQuery } from 'react-responsive'


export const ContactPage = forwardRef((props, contactRef) => {

    const isMobile = useMediaQuery({ maxWidth: 762 })

    const {colors} = useContext(ThemeContext)

    const leftBg = "red"
    const rightBg = "brown"

    const leftContent = <>
        <StyledH2 pos={"end"} color={rightBg} colors={colors}>
            {'.getIn'}
        </StyledH2> 
        <StyledIcon colors={colors} color={rightBg}>
            <a href="mailto: kasperfryd@gmail.com">
                <MailOutlineIcon/>
            <StyledH3 pos={'center'} color={rightBg} colors={colors}>mail</StyledH3>
            </a>
        </StyledIcon>
        </>

    const rightContent = <>
        <StyledH2 pos={"start"} color={leftBg} colors={colors}>
            {'Touch'}        
        </StyledH2> 
        <StyledIcon colors={colors} color={leftBg}>
            <a href="https://www.linkedin.com/in/kasper-frydensberg-49a9bb110/">
                <LinkedInIcon/>
            <StyledH3 pos={'center'} color={leftBg} colors={colors}>linkedin</StyledH3>
            </a>
        </StyledIcon>
        </>

    const combinedContent = <>
        <StyledH2 pos={"center"} color={rightBg} colors={colors}>
            {'.getInTouch'}        
        </StyledH2> 
        <StyledIcon colors={colors} color={rightBg}>
            <a href="https://www.linkedin.com/in/kasper-frydensberg-49a9bb110/">
                <LinkedInIcon/>
                <StyledH3 pos={'center'} color={rightBg} colors={colors}>linkedin</StyledH3>
            </a>
        </StyledIcon>
        <StyledIcon colors={colors} color={rightBg}>
            <a href="mailto: kasperfryd@gmail.com">
                <MailOutlineIcon/>
                <StyledH3 pos={'center'} color={rightBg} colors={colors}>mail</StyledH3>
            </a>
        </StyledIcon>
    </>

    return (
        <PageComponent areaRef={contactRef} left_bg={leftBg} right_bg={rightBg} left={!isMobile ? leftContent : combinedContent} right={!isMobile ? rightContent : null}></PageComponent>
    )
})