import React, { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext'
import { StyledTopButton } from '../StyledComponents/StyledComponents'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export const BackButtonComponent = (props) =>  {

    const {scrollPercent} = useContext(ThemeContext)

    return(
        <StyledTopButton scroll={scrollPercent} onClick={() => props.handleBackClick(0)}>
            <ArrowUpwardIcon/>
        </StyledTopButton>
    )
}