import React, {forwardRef, useContext } from 'react'
import { PageComponent } from '../../components/PageComponent/PageComponent'
import { ThemeContext } from "../../context/ThemeContext"
import {StyledP, StyledImage} from '../../components/StyledComponents/StyledComponents'

export const AboutPage = forwardRef((props, aboutRef) => {

    const {colors} = useContext(ThemeContext)

    const leftBg = "brown"
    const rightBg = "red"

    const leftContent = <StyledP colors={colors}>
        {`"My name is Kasper. \n \n im a webdeveloper, situated in Aalborg, Denmark. I love playful, minimalistic design. 
        \n I eat Javascript for breakfast and shower in React"`}
        </StyledP>

    const rightContent = <StyledImage colors={colors}></StyledImage>


    return (
        <PageComponent areaRef={aboutRef} left={leftContent} right={rightContent} left_bg={leftBg} right_bg={rightBg}/>
    )
})